export const LOT_TYPES = {
  VEHICLE: 'ContentfulVehicle',
  AUTOMOBILIA: 'ContentfulAutomobilia',
};

export const VEHICLE_TYPES = {
  CAR: 'Car',
  MOTORCYCLE: 'Motorcycle',
};

export const LOT_TYPES_LABELS = {
  CAR: 'car',
  MOTORCYCLE: 'motorcycle',
  ITEM: 'item',
};
